<template>
  <div class="ca-page">
    <v-row class="ca-page-title">
      <v-col>
        <h1 class="ca-title">Video Vibes</h1>
        <h2 class="ca-subtitle">
          Which Sokal spot best represents your style?
        </h2>
      </v-col>
      <v-col class="d-none d-sm-flex justify-end">
        <router-link to="/spots" target="_blank">
          <div class="text-center d-flex">
            <span class="black--text mr-2">
              View full <br />
              videos of Sokal <br />
              spot types
            </span>

            <v-img
              max-width="93"
              src="../assets/images/buttons/orange-chevrons.png"
            />
          </div>
        </router-link>
      </v-col>
    </v-row>
    <v-row class="ca-content">
      <v-col class="ca-spots ca-options">
      <v-row v-for="(chunk, i) in chunkPictures" :key="i">
        <v-col v-for="(col, i) in chunk" sm="6" cols="12" :key="i">
          <spot :option="col.option" :jpg="col.jpg" :gif="col.gif" :url="col.url"></spot>
        </v-col>
      </v-row>
    </v-col>
    </v-row>
    <v-row class="d-flex d-sm-none">
      <v-col>
        <router-link to="/spots" target="_blank">
          <img class="spot-link-img" src="../assets/images/buttons/orange-chevrons.png">
          <span class="ml-2 spot-link-text">
            View full videos of Sokal spot types
          </span>
        </router-link>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from "lodash";
import Spot from "../components/Spot.vue";
export default {
  data() {
    return {
      spots: [
        {
          option: "deal card",
          jpg: require("../assets/images/spots/Deal_Card-grayscale.jpg"),
          gif: require("../assets/images/spots/Deal_Card.gif"),
          url: "https://vimeo.com/683024311/4f1ba33e0f"
        },
        {
          option: "graphic-centered",
          jpg: require("../assets/images/spots/Graphic-Centered_grayscale.jpg"),
          gif: require("../assets/images/spots/Graphic-Centered.gif"),
          url: "https://vimeo.com/683024816/b7d00dd1b0"
        },
        {
          option: "on location shoot",
          jpg: require("../assets/images/spots/On_Location_Shoot_grayscale.jpg"),
          gif: require("../assets/images/spots/On_Location_Shoot.gif"),
          url: "https://vimeo.com/683025513/37df81b5d0"
        },
        {
          option: "spokesperson",
          jpg: require("../assets/images/spots/Spokesperson_Grayscale.jpg"),
          gif: require("../assets/images/spots/Spokesperson.gif"),
          url: "https://vimeo.com/683026169/8c211c4d20"
        },
      ],
    };
  },
  components: {
    Spot,
  },
  computed: {
    chunkPictures() {
      return _.chunk(this.spots, 2);
    },
  },
};
</script>

<style scoped>
.spot-link-img {
  height: 16px;
  position: relative;
  top: 2px;
}
.spot-link-text {
  font-size: 16px;
  text-decoration: none !important;
  color: #ff4438;
  transition: .3s all ease;
}
.spot-link-text:hover {
  color: #db362c;
}
</style>
